<template>
  <div class="view view--references">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{
            name: 'ressourceLogs',
            params: {
              ressource: ressource,
              ressourceid: ressource_id,
              title:title,
              from:from
            },
          }"
        />
        <h1 class="page-title">
          {{ pageTitle }} - {{ log.objet }} - identifiant: {{ log.id }}
        </h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <!-- TODO: Nico -->
          <div v-highlight>
            <pre class="language-json"><code>{{ log }}</code></pre>
          </div>
        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import 'prism-es6/components/prism-markup-templating'
import 'prism-es6/components/prism-json'

export default {
  name: 'LogView',

  components: {
    Btn,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
      default: 'Log',
    },
  },

  data() {
    return {
      title: null,
      ressource: null,
      ressource_id: null,
      from: null,
      log: [],
    }
  },

  mounted() {
    this.ressource_id = this.$route.params.ressourceid
    this.ressource = this.$route.params.ressource
    this.title = this.$route.params.title || this.$route.meta.title
    if (this.$route.params.from !== undefined) {
      this.from = this.$route.params.from
    }
    this.getLog()
  },

  methods: {
    getLog() {
      this.fetchService
        .get(`log/${this.$route.params.logid}`)
        .then((response) => {
          this.log = response.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
